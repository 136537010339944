module.exports ={
  "propertyService": "https://ibktqtqrsl.execute-api.us-east-1.amazonaws.com/int",
  "domusoApiService": "https://gn7l34tn6a.execute-api.us-east-1.amazonaws.com/int",
  "appService": "https://domusograilsweb-int.devmuso.com",
  "reservationService": "https://ritsgpz6j3.execute-api.us-east-1.amazonaws.com/int",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://twsgqk0v4a.execute-api.us-east-1.amazonaws.com/int",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-int.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-int.devmuso.com",
  "maintenanceUrl": "https://twsgqk0v4a.execute-api.us-east-1.amazonaws.com/int/maintenance",
  "env": "int"
}